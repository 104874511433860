import React from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <footer className="footer-kb">
      {/* Add footer content here */}
      <p>&copy; {currentYear} Rishant Technologies Pvt Ltd.</p>
    </footer>
  );
};

export default Footer;