import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import LeftNav from './LeftNav';
import Footer from './Footer';
import './wiki.css';
import WikiComponent from './WikiComponent';
import { WIKI_ROUTES } from '../../constants/wiki_routes';
import { PRIVATE_ROUTES } from '../../constants/private_route';

function DocLayout({ category }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  if (!category) category = 'company';
  const [content, setContent] = useState(null);
  const { page_title } = useParams();
  const [contentUrl, setContentUrl] = useState('/wiki_data/sample.json');

  useEffect(() => {
    const loadContent = async () => {
      if (!page_title) return; // Home page content is already passed as prop

      let route = WIKI_ROUTES.find(r => r.path === `/wiki/${category}/${page_title}`);
      if (!route) {
        route = PRIVATE_ROUTES.find(r => r.path === `/page/${page_title}`);
      }

      const categoryPath = category === 'company' ? 'company' : `${category}`;

      if (!route) {
        console.warn(`Route not found for ${page_title} under category ${category}. Falling back to default content.`);
        setContent({
          title: 'Default Title',
          author: 'Default Author',
          date: new Date().toLocaleDateString(),
          content: [
            {
              type: 'paragraph',
              text: 'This is the default content as the specific content was not found.'
            }
          ]
        });
        if(categoryPath) {
          setContentUrl(`/wiki_data/${categoryPath}/${page_title}.json`);
        } else {
          setContentUrl(`/wiki_data/${page_title}.json`); // Optionally set a default URL if needed
        }
        return;
      }      

      console.log('categoryPath', categoryPath);
      try {
        const response = await fetch(`/wiki_data/${categoryPath}/${page_title.toLowerCase()}.json`);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.json();
        setContent(data);
        setContentUrl(`/wiki_data/${categoryPath}/${page_title.toLowerCase()}.json`);
      } catch (error) {
        console.error('Error loading content:', error);
        setContent({
          title: 'Fallback Title',
          author: 'Fallback Author',
          date: new Date().toLocaleDateString(),
          content: [
            {
              type: 'paragraph',
              text: 'This is the fallback content as the specific content was not found.'
            }
          ]
        });
        setContentUrl('/wiki_data/sample.json');
      }
    };

    if (page_title) {
      loadContent();
    }
  }, [page_title, category]);

  if (!content) return <div>Loading...</div>;
  if (content.error) return <div>{content.error}</div>;

  const getTitle = () => {
    if (category === 'hiring') return 'Hiring Wiki';
    if (category === 'engineering') return 'Engineering Wiki';
    return 'Rishant Technologies';
  };

  return (
    <div className="app-wiki">
      <header className="header-wiki">
        {category === 'company' || !category ? (
          <Link to="/">
            <h1>{getTitle()}</h1>
          </Link>
        ) : (
          <h1>{getTitle()}</h1>
        )}
        {category && <input type="search" placeholder="Search" className="search-bar-wiki" />}
        <span className="menu-toggle" onClick={toggleMenu}>
          {isOpen ? '✕' : '☰'}
        </span>
      </header>
      <div className="main-content-wiki">
        {/* <LeftNav category={category} /> */}
        <LeftNav category={category} isOpen={isOpen} />
        <main>
          <WikiComponent contentUrl={contentUrl} />
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default DocLayout;
