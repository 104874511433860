import React, { useState, useEffect } from 'react';
import './wiki.css';
// import LikeDislike from './LikeDislike';
// import Share from './Share';
import Subscribe from './Subscribe';

const WikiComponent = ({ contentUrl }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    fetch(contentUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => setContent(data))
      .catch(error => console.error('Error loading content:', error));
  }, [contentUrl]);

  const renderContent = (item) => {
    switch (item.type) {
      case 'heading':
        switch (item.level) {
          case 1:
            return <h1>{item.text}</h1>;
          case 2:
            return <h2>{item.text}</h2>;
          case 3:
            return <h3>{item.text}</h3>;
          case 4:
            return <h4>{item.text}</h4>;
          case 5:
            return <h5>{item.text}</h5>;
          case 6:
            return <h6>{item.text}</h6>;
          default:
            return <h3>{item.text}</h3>;
        }
      case 'paragraph':
        return <p>{item.text}</p>;
      case 'highlight':
        return <p className="highlight">{item.text}</p>;
      case 'code':
        return (
          <pre>
            <code>{item.text}</code>
            <button onClick={() => navigator.clipboard.writeText(item.text)}>Copy</button>
          </pre>
        );
      case 'list':
        return item.ordered ? (
          <ol>{item.items.map((li, index) => <li key={index}>{li}</li>)}</ol>
        ) : (
          <ul>{item.items.map((li, index) => <li key={index}>{li}</li>)}</ul>
        );
      case 'image':
        return <img src={item.src} alt={item.alt} />;
      default:
        return null;
    }
  };

  if (!content) return <div>Loading...</div>;

  return (
    <div className="wiki-content">
      <h1>{content.title}</h1>
      {content.author && (
      <p className="meta">
        <strong>{content.author}</strong> | {content.date}
      </p>
      )}
      <div>
        {content.content.map((item, index) => (
          <div key={index}>
            {renderContent(item)}
          </div>
        ))}
      </div>
      {/* <LikeDislike /> */}
      {/* <Share articleUrl={window.location.href} /> */}
      <div className="divider"></div>
      <Subscribe />
    </div>
  );
};

export default WikiComponent;
