import React from 'react';
import { wiki_topics } from './engineering_menu';
// import { hiring_topics } from './hiring_menu'; // Assuming hiring_menu.js exports hiring_topics
import { job_topics } from './job_menu'; // Assuming hiring_menu.js exports hiring_topics
import { website_menus } from '../common/website_menu';

const LeftNav = ({ category, isOpen }) => {
  let categoryName = category || 'company';

  console.log('category', category);

  const topics = category === 'engineering' ? wiki_topics :
                 category === 'hiring' ? job_topics : website_menus;

  return (
    <div>
      <div className={`left-nav ${isOpen ? 'open' : ''}`}>
        {topics.map((category) => (
          <div key={category.category}>
            <h3>{category.category}</h3>
            <ul>
              {category.items.map((item) => (
                <li key={item.url}>
                  <a href={`/wiki/${categoryName}/${item.url}`}>
                    <span className="icon">{item.icon}</span> {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftNav;
