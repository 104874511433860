import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import SideMenu from './components/SideMenu';
import MainContent from './components/MainContent';
import Notes from './components/Notes';
import Secrets from './components/Secrets';
import { ROUTES, HOME_ROUTE } from './constants/routes';
import JobSearch from './components/jobs/JobMainLayout';
// import homeData from './data/home.json';
import './styles.css';
import { useHideSideMenu } from './hooks/useHideSideMenu';
import MainLayout from './components/wiki/MainLayout';
import DocLayout from './components/wiki/DocLayout';
import KnowledgeBase from './components/kb/KnowledgeBase';
// import PageLayout from './components/wiki/PageLayout';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Login from './components/kb/Login';
import Profile from './components/kb/profile/Profile';

const firebaseConfig = {
  apiKey: "AIzaSyDbwmbXIB0vP8gqB8lUQTke2_hrenMZDVY",
  authDomain: "rishant-tech-website.firebaseapp.com",
  projectId: "rishant-tech-website",
  storageBucket: "rishant-tech-website.appspot.com",
  messagingSenderId: "88817045840",
  appId: "1:88817045840:web:794779d4aea0002935dc55",
  measurementId: "G-0RGBKBNL67"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

function AppContent() {
  const location = useLocation();
  const hideSideMenu = useHideSideMenu(location.pathname);

  return (
    <div className="app-container">
      <main className="main-content">
        <Routes>
          {/* <Route path={HOME_ROUTE.path} element={<MainContent content={homeData} />} /> */}
          <Route path="/:page_title" element={<MainContent />} />
          <Route path="/n/notes" element={<Notes />} />
          <Route path="/n/secrets" element={<Secrets />} />
          <Route path="/f/:page_title" element={<MainContent />} />
        </Routes>
      </main>
      {!hideSideMenu && <SideMenu items={ROUTES} />}
    </div>
  );
}

function WikiRouteWrapper({ element: Element }) {
  const { category } = useParams();
  return <Element category={category} />;
}

function App() {
  const location = useLocation();
  const isWikiRoute = location.pathname.startsWith('/wiki') || location.pathname === '/' || location.pathname.startsWith('/jobs') || location.pathname.startsWith('/page') || location.pathname.startsWith('/kb') || location.pathname.startsWith('/crm');

  return (
    <>
      {!isWikiRoute && <AppContent />}
      <Routes>
        <Route path={HOME_ROUTE.path} element={<WikiRouteWrapper element={MainLayout} />} />
        <Route path="/kb" element={<KnowledgeBase />} />
        <Route path="/kb/profile" element={<Profile />} />
        <Route path="/crm/login" element={<Login />} />
        <Route path="/wiki/:category" element={<WikiRouteWrapper element={MainLayout} />} />
        <Route path="/jobs/*" element={<JobSearch />} />
        <Route path="/wiki/:category/:page_title" element={<WikiRouteWrapper element={DocLayout} />} />
        <Route path="/wiki/*" element={<WikiRouteWrapper element={MainLayout} />} />
        <Route path="/page" element={<MainLayout />} />
        <Route path="/page/:page_title" element={<MainLayout />} />
      </Routes>
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
