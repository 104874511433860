import React from 'react';
import './BodyContent.css';

const BodyContent = () => {
  return (
    <main className="body-content">
      <h1>Resources</h1>
      <p>Stay up-to-date with the latest industry trends, and explore how RT CRM for Teams can empower teams in your organization.</p>
      <hr />
      <h2>Featured</h2>
      <div className="featured-articles">
        <article>
          {/* <img src="/path-to-ai-image.png" alt="AI Code Generation" /> */}
          <h3>Better together: Getting the most value from AI code generation tools</h3>
          <p>In this article, we'll delve into the advantages and drawbacks of AI code generation tools.</p>
        </article>
        {/* <article>
          <img src="/path-to-overflowai-image.png" alt="OverflowAI" />
          <h3>Introducing OverflowAI</h3>
          <p>OverflowAI combines the innovative power of GenAI with RT CRM's trusted knowledge...</p>
        </article> */}
      </div>
    </main>
  );
};

export default BodyContent;