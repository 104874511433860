import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ id, image, title, tags }) => {
  return (
    <Link to={`/wiki/${id}`} className="card">
      <img src="https://images.unsplash.com/photo-1421091242698-34f6ad7fc088?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1200" alt={title} />
      <h3>{title}</h3>
      <div className="tags">
        {tags.map((tag, index) => (
          <span key={index} className="tag">{tag}</span>
        ))}
      </div>
    </Link>
  );
};

export default Card;