export const job_topics = [
    {
        "category": "Finding a Job",
        "items": [
            { "title": "Top Job Search Strategies", "url": "top-job-search-strategies" },
            { "title": "Leveraging Job Boards Effectively", "url": "leveraging-job-boards-effectively" },
            { "title": "Using LinkedIn for Job Search", "url": "using-linkedin-for-job-search" },
            { "title": "How to Find Remote Job Opportunities", "url": "how-to-find-remote-job-opportunities" },
            { "title": "Freelance vs. Full-time", "url": "freelance-vs-full-time" },
            { "title": "How to Research Potential Employers", "url": "how-to-research-potential-employers" },
            { "title": "Job Search During a Recession", "url": "preparing-for-job-search-during-recession" }
        ]
    },
    {
        "category": "Growing in Career",
        "items": [
            { "title": "Setting Career Goals", "url": "setting-career-goals" },
            { "title": "How to Get a Promotion", "url": "how-to-get-promotion" },
            { "title": "Developing Leadership Skills", "url": "developing-leadership-skills" },
            { "title": "How to Find a Mentor", "url": "how-to-find-mentor" },
            { "title": "Effective Time Management Strategies", "url": "effective-time-management-strategies" },
            { "title": "Continuous Learning and Development", "url": "continuous-learning-development" },
            { "title": "Building a Personal Brand", "url": "building-personal-brand" },
        ]
    }
];
