import React, { useState } from 'react';
import './Subscribe.css'; // Add some basic styles for form

const Subscribe = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    // Handle email subscription logic here
    alert(`Subscribed with email: ${email}`);
  };

  return (
    <div className="subscribe">
      <input 
        type="email" 
        placeholder="Enter your email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />
      <button onClick={handleSubscribe}>Subscribe</button>
    </div>
  );
};

export default Subscribe;
