import React from 'react';
import Card from './Card';
import { cardData } from './cardData';

const CardGrid = () => {
  return (
    <div className="card-grid">
      {cardData.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </div>
  );
};

export default CardGrid;