export const website_menus = [
    {
        "category": "About",
        "items": [
            { "title": "What we do", "url": "what-we-do", "icon": "✓" },
            { "title": "Why we started", "url": "why-we-started", "icon": "⚛" },
            { "title": "How we work", "url": "how-we-work", "icon": "🖥" }
        ]
    },
    {
        "category": "Product & Services",
        "items": [
            { "title": "Building Applications", "url": "building-applications", "icon": "🚀" },
            { "title": "CRM Softwares", "url": "crm-softwares", "icon": "📘" },
            { "title": "Personal Websites", "url": "personal-websites", "icon": "🔄" },
            { "title": "LMS", "url": "lms", "icon": "🚢" }
        ]
    }
];
