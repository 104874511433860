import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function SideMenu({ items }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
      <div className="side-menu-header">
        {/* <span>RT</span> */}
        <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? '✕' : '☰'}
        </button>
      </div>
      <div className="menu-items">
        {items.map((item, index) => (
          <Link key={index} to={item.path} onClick={() => setIsMenuOpen(false)}>
            {item.name}
          </Link>
        ))}
      </div>
    </nav>
  );
}

export default SideMenu;