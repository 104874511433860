import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { PRIVATE_ROUTES } from '../constants/private_route';
import Footer from './wiki/Footer';

function MainContent({ content: initialContent }) {
  const [content, setContent] = useState(initialContent);
  const { page_title } = useParams();

  useEffect(() => {
    const loadContent = async () => {
      if (!page_title) return; // Home page content is already passed as prop

      let route = ROUTES.find(r => r.path === `/${page_title}`);
      if(!route) {
          route = PRIVATE_ROUTES.find(r => r.path === `/f/${page_title}`);
      }
      if (!route) {
        setContent({ error: 'Page not found' });
        return;
      }

      try {
        const module = await import(`../data/${route.name.toLowerCase()}.json`);
        setContent(module.default);
      } catch (error) {
        console.error('Error loading content:', error);
        setContent({ error: 'Content not found' });
      }
    };

    if (page_title) {
      loadContent();
    }
  }, [page_title]);

  if (!content) return <div>Loading...</div>;
  if (content.error) return <div>{content.error}</div>;

  // Helper function to render content based on its type
  const renderContent = (item) => {
    if (typeof item === 'string') {
      return <p dangerouslySetInnerHTML={{ __html: item }} />;
    }
    if (Array.isArray(item)) {
      return (
        <ul>
          {item.map((point, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: point }} />
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="content-wrapper">
      <h1>{content.name}</h1>
      {content.image && <img src={content.image} alt="Profile" className="profile-image" />}
      <p>{content.description}</p>
      {content.email && <p>Email: <a href={`mailto:${content.email}`}>{content.email}</a></p>}
      {content.interests && (
        <>
          <h2>Smarter Solutions, Brighter Tomorrows:</h2>
          <ul>
            {content.interests.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </>
      )}
      {content.elsewhere && (
        <>
          <h2>Contact</h2>
          <p dangerouslySetInnerHTML={{ __html: content.elsewhere }} />
        </>
      )}

    {content.sections && content.sections.map((section, index) => (
        <div key={index}>
          <h2>{section.title}</h2>
          {renderContent(section.points)}
        </div>
      ))}
      <Footer />
    </div>
  );
}

export default MainContent;