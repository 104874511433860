export const ROUTES = [
    {
      name: 'Home',
      path: '/',
      description: 'Rishant Technologies',
      displayTitle: ''
    },
    {
      name: 'Terms',
      path: '/terms',
      description: 'Terms of Service',
      displayTitle: 'Terms'
    },
    {
      name: 'Privacy',
      path: '/privacy',
      description: 'Privacy Policy',
      displayTitle: 'Privacy'
    },
    {
        name: 'Payments',
        path: '/payments',
        description: 'Payment',
        displayTitle: 'Payment'
      },
      {
        name: 'Contact',
        path: '/contact',
        description: 'Contact Us',
        displayTitle: 'Contact'
      },  
    // ... add more routes as needed
    {
        name: 'Notes',
        path: '/n/notes',
        description: 'Personal notes',
        displayTitle: 'My Notes'
    },
    {
        name: 'Secrets',
        path: '/n/secrets',
        description: 'Secrets/password',
        displayTitle: 'Secret Manager'
    }
  ];
  
  export const HOME_ROUTE = {
    name: 'Home',
    path: '/',
    description: 'Rishant Technologies',
    displayTitle: 'Rishant Technologies'
  };