export const cardData = [
    {
      id: 1,
      image: '/images/development-lifecycle.jpg',
      title: 'Development Lifecycle',
      tags: ['Guides and Processes']
    },
    {
      id: 2,
      image: '/images/useful-commands.jpg',
      title: 'Useful Commands',
      tags: ['Guides and Processes', 'Codebase']
    },
    {
      id: 3,
      image: '/images/getting-started.jpg',
      title: 'Getting Started',
      tags: ['Guides and Processes']
    },
    {
      id: 4,
      image: '/images/engineering-guidelines.jpg',
      title: 'Engineering Guidelines',
      tags: ['Guides and Processes']
    },
    {
      id: 5,
      image: '/images/how-to-deploy.jpg',
      title: 'How to Deploy',
      tags: ['Guides and Processes']
    },
    {
      id: 6,
      image: '/images/engineering-interviews.jpg',
      title: 'Engineering Interviews',
      tags: ['Guides and Processes']
    },
    {
      id: 7,
      image: '/images/how-to-qa.jpg',
      title: 'How to QA',
      tags: ['Testing', 'Guides and Processes']
    }
  ];