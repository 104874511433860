export const PRIVATE_ROUTES = [
    {
      name: 'Latika',
      path: '/f/latika',
      description: 'Wifey',
      displayTitle: 'Latika'
    },
    {
      name: 'Bookmarks',
      path: '/f/links',
      description: 'Bookmarks',
      displayTitle: 'Bookmarks'
    }
];