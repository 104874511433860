export const wiki_topics = [
    {
        "category": "Codebase",
        "items": [
            { "title": "Code Reviews", "url": "code-reviews", "icon": "✓" },
            { "title": "React", "url": "react", "icon": "⚛" },
            { "title": "Backend", "url": "backend", "icon": "🖥" },
            { "title": "AWS", "url": "aws", "icon": "☁" },
            { "title": "Redis", "url": "redis", "icon": "🔴" },
            { "title": "CircleCI", "url": "circleci", "icon": "⚪" }
        ]
    },
    {
        "category": "Guides & Processes",
        "items": [
            { "title": "Getting Started", "url": "getting-started", "icon": "🚀" },
            { "title": "Engineering Guidelines", "url": "engineering-guidelines", "icon": "📘" },
            { "title": "Development Lifecycle", "url": "development-lifecycle", "icon": "🔄" },
            { "title": "How to Deploy", "url": "how-to-deploy", "icon": "🚢" },
            { "title": "Useful Commands", "url": "useful-commands", "icon": "🔧" }
        ]
    }
];
