import React, { useState } from 'react';
import LeftNav from './LeftNav';
import CardGrid from './CardGrid';
import Footer from './Footer';
import PageLayout from './PageLayout';
import { Link } from 'react-router-dom';
import './wiki.css';

function MainLayout({ category }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getTitle = () => {
    if (category === 'hiring') return 'Hiring Wiki';
    if (category === 'engineering') return 'Engineering Wiki';
    return 'Rishant Tech';
  };

  return (
    <div className="app-wiki">
      <header className="header-wiki">
      <Link to="/">
        <h1>{getTitle()}</h1>
      </Link>
        <input type="search" placeholder="Search" className="search-bar-wiki" />
        <span className="menu-toggle" onClick={toggleMenu}>
          {isOpen ? '✕' : '☰'}
        </span>
      </header>
      <div className="main-content-wiki">
        <LeftNav category={category} isOpen={isOpen} />
        <main>
          {/* <h2>Guides and Process</h2> */}
          {category === 'hiring' ? <PageLayout customContent="freehire.json" /> : category ? <CardGrid /> : <PageLayout />}
          {!category && <PageLayout />}
          {/* <PageLayout /> */}
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default MainLayout;
