import React from 'react';
import './RightBar.css';

const RightBar = () => {
  return (
    <aside className="right-bar">
      <div className="stay-updated">
        <h3>Stay updated</h3>
        <p>Subscribe to receive RT CRM for Teams content around knowledge sharing, collaboration, and AI.</p>
        <button>Receive updates</button>
      </div>
    </aside>
  );
};

export default RightBar;