export const WIKI_ROUTES = [
    {
      name: 'What we do',
      path: '/wiki/company/what-we-do',
      description: 'What we do',
      displayTitle: 'what-we-do'
    },
    {
      name: 'LMS',
      path: '/wiki/company/lms',
      description: 'LMS',
      displayTitle: 'lms'
    },
    {
      name: 'CRM Softwares',
      path: '/wiki/company/crm-softwares',
      description: 'CRM Softwares',
      displayTitle: 'crm'
    },
    {
      name: 'Personal Websites',
      path: '/wiki/company/personal-websites',
      description: 'Personal Websites',
      displayTitle: 'personal-websites'
    },
    {
      name: 'Building Applications',
      path: '/wiki/company/building-applications',
      description: 'Building Applications',
      displayTitle: 'building-applications'
    }
]