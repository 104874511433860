import React, { useState } from 'react';
import { FaBriefcase, FaGraduationCap, FaMapMarkerAlt, FaEye, FaCalendarAlt } from 'react-icons/fa';
import './Profile.css';

const Profile = () => {

  const [activeTab, setActiveTab] = useState('Profile');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Profile':
        return <ProfileContent />;
      case 'Answers':
        return <AnswersContent />;
      // Add cases for other tabs
      default:
        return <ProfileContent />;
    }
  };

  return (
    <div className="fh-profile">
      <header>
        <div className="logo">Free Hire</div>
        <button className="sign-in">Sign In</button>
      </header>
      
      <main>
        <div className="profile-container">
          <div className="profile-left">
            <img src="https://lh3.googleusercontent.com/ogw/AF2bZyhgoe1_PG0koUxggjbadIJGsc2DLpIRWqA1wXhJtl231ww8=s32-c-mo" alt="Profile" className="profile-image" />
            <h1>Katie Chukku</h1>
            <p className="follower-info">83 followers · 37 following</p>
            <p className="user-description">
                I am a physician,I teach medicine, interested in human psychology,productivity,habit 
                formation, personal finances, love reading books ,a mother and wife of an amazing 
                person.
            </p>
            
            <nav className="profile-tabs">
            <a 
                href="/kb/profile" 
                className={activeTab === 'Profile' ? 'active' : ''}
                onClick={() => handleTabClick('Profile')}
              >
                Profile
              </a>
              <a 
                href="/kb/profile" 
                className={activeTab === 'Answers' ? 'active' : ''}
                onClick={() => handleTabClick('Answers')}
              >Answers</a>
              <a href="/kb/profile">Questions</a>
              <a href="/kb/profile">Post</a>
              <a href="/kb/profile">Followers</a>
              <a href="/kb/profile">Following</a>
              <a href="/kb/profile">Edits</a>
              <a href="/kb/profile">Activity</a>
            </nav>

            <div className="profile-content">
              {renderTabContent()}
            </div>
            
            <div className="profile-content">
              <h2>Profile</h2>
              <div className="profile-item">
                {/* <img src="profile-image-small.jpg" alt="Profile" /> */}
                <div>
                  <h3>Rish Shan</h3>
                  <p>Toddler · 2y</p>
                </div>
              </div>
              <div className="question-item">
                {/* <img src="user-avatar.jpg" alt="User" /> */}
                <div>
                  <h3>Latika Sinha · 31y</h3>
                  <h4>What are some good early morning habits?</h4>
                  <p>1.Wake up earlier - up earlier - You would be amazed at how much work you can get done if you can wake up even an hour earlier than the rest of the world.For some of us waking up early is a challenge . It helps to plan your next morni... <span className="more-link">more</span></p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="profile-right">
            <section className="credentials">
              <h2>Credentials & Highlights</h2>
              <ul>
                <li><FaBriefcase /> Works at BANGALORE NETHRALAYA</li>
                <li><FaGraduationCap /> Studied at Belgaum, Karnataka, India</li>
                <li><FaMapMarkerAlt /> Lives in Bengaluru, Karnataka, India</li>
                <li><FaEye /> 3 content views</li>
                <li><FaCalendarAlt /> Joined September 2013</li>
              </ul>
            </section>
            
            <section className="knows-about">
              <h2>Knows about</h2>
              <ul>
                <li>Bengaluru, Karnataka, India</li>
                <li>Belgaum, Karnataka, India</li>
                <li>Eyes</li>
                <li>Eyesight</li>
                <li>Laser-Assisted In Situ Keratomileusis (LASIK)</li>
              </ul>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}

const ProfileContent = () => (
    <>
      <h2>Profile</h2>
      {/* Add profile content here */}
    </>
  );
  
  const AnswersContent = () => (
    <>
      <h2>Answers</h2>
      {/* Add answers content here */}
    </>
  );

export default Profile;