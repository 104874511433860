import React from 'react';
import TopBar from './TopBar';
import SideBar from './SideBar';
import BodyContent from './BodyContent';
import RightBar from './RightBar';
import Footer from './Footer';
import './KnowledgeBase.css';

const KnowledgeBase = () => {
  return (
    <div className="kb-container">
      <TopBar />
      <div className="kb-main">
        <SideBar />
        <BodyContent />
        <RightBar />
      </div>
      <Footer />
    </div>
  );
};

export default KnowledgeBase;