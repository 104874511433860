// src/components/wiki/PageLayout.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './wiki.css';

const PageLayout = ({ customContent }) => {
  const { page_title } = useParams();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const filename = customContent ? customContent : page_title ? `${page_title}.json` : 'home.json';
      // const filename = page_title ? `${page_title}.json` : 'home.json';
      try {
        const response = await fetch(`/company/${filename}`);
        if (!response.ok) {
          throw new Error('Not Found');
        }
        const data = await response.json();
        setContent(data);
      } catch (error) {
        const response = await fetch('/company/404.json');
        const data = await response.json();
        setContent(data);
      }
    };

    fetchData();
  }, [page_title, customContent]);

  if (!content) return <div>Loading...</div>;

  return (
    <div className="content-section">
      <h1>{content.title}</h1>
      <p>{content.introduction}</p>
      {content.sections && content.sections.map((section, index) => (
        <div key={index}>
          <h2>{section.heading}</h2>
          {Array.isArray(section.content) ? (
            section.content.map((item, i) => (
              <div key={i}>
                <h3>{item.name || item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))
          ) : (
            <p>{section.content}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PageLayout;
