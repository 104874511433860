import React from 'react';
import './SideBar.css';

const SideBar = () => {
  return (
    <aside className="side-bar">
      <div className="featured-guide">
        <span className="star-icon">★</span>
        <p>Check out our guide to building a GenAI program within your organization</p>
        <a href="/kb#">Start reading &gt;</a>
      </div>
      {/* <nav>
        <h3>Productivity</h3>
        <ul>
          <li>Knowledge Sharing</li>
          <li>Collaboration</li>
          <li>Upskilling</li>
        </ul>
        <h3>AI/ML</h3>
        <ul>
          <li>RT CRM + AI</li>
          <li>AI Insights & Trends</li>
          <li>AI Tips & Recommendations</li>
        </ul>
      </nav> */}
    </aside>
  );
};

export default SideBar;